export default {
  //apipalvelin: 'http://localhost:5000/api/v1',
  //apipalvelin: "https://api.teuva.fi/api/v1",
  apipalvelin: "https://api.karijoki.fi/api/v1",
  //apipalvelin: "https://api.kauhajoki.fi/api/v1",

  //apistatic: 'http://127.0.0.1:5000/static'
  //apistatic: "https://api.teuva.fi/static",
  apistatic: "https://api.karijoki.fi/static",
  //apistatic: "https://api.kauhajoki.fi/static",
};
